import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"


const Container = styled.div `
  text-align: center;
  padding-top:1rem;
`

const OuterContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 30vh;
`

const Description = styled.p `
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  @media only screen and (max-width: 342px) {
    font-size:1.2rem;
  }
`
const Content = styled.p `
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  text-decoration: none; 
`

const NameHeader = styled.h1 `
  font-weight: 500;
  font-size: 4.2rem;
  margin-bottom: 0;
  @media only screen and (max-width: 360px) {
    font-size:3.5rem;
  }
`

const LandingBio = () => ( <
    StaticQuery query = { graphql `
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    ` }
    render={
        data => ( 
            <OuterContainer>
            <Container>
            <img src="https://scontent-lax3-2.xx.fbcdn.net/v/t1.0-9/67356181_2633195890025957_8866576910163902464_o.jpg?_nc_cat=104&_nc_oc=AQnVdWvcJxgREdgB3VuCg1RXZx0z6MYJ32yxmQukLs-DqkdxoXdtMuiHo5VjZSJUles&_nc_ht=scontent-lax3-2.xx&oh=ae20ce06f411eab9d2dd7214dbad39fc&oe=5E551AEA" class="picture" alt="Agam Jolly Picture"></img>
            <NameHeader> { data.site.siteMetadata.title } </NameHeader> 
            <Description> Student and Full-Stack Developer</Description>
            <Content>
            </Content>   
            </Container> 
            </OuterContainer>
        )
    }
    />
)

NameHeader.propTypes = {
    siteTitle: PropTypes.string,
}

NameHeader.defaultProps = {
    siteTitle: ``,
}

export default LandingBio